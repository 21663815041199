<template>
  <v-card class="pa-2 mt-2">
    <v-form ref="responseForm">
      <v-layout row wrap mt-2 px-4 py-3>
        <p>
          You are required to respond to the dispute made by the client within 10 days. Failure to do so will automatically resolve said dispute in the client’s favor.
        </p>
        <v-textarea
          outlined
          auto-grow
          label="Type your response here."
          counter="2500"
          v-model="message"
          :rules="messageRules"
        ></v-textarea>
      </v-layout>
      <v-card-actions class="lightBlack pa-3">
        <v-spacer></v-spacer>
        <v-btn
          large
          depressed
          color="primary"
          :loading="btnLoading"
          @click="createResponse"
        >Submit Response</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import io from 'socket.io-client'

export default {
  props: {
    disputeId: {
      type: String,
      required: true
    },
    complainant: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      socket: io(process.env.VUE_APP_SERVER),
      btnLoading: false,
      messageRules: [
        (v) => !!v || 'Please enter your message.',
        (v) => (v && v.length >= 30) || 'Please enter at least 30 characters.',
        (v) => (v && v.length <= 2500) || 'Please enter at most 3000 characters.'
      ],
      message: ''
    }
  },
  methods: {
    createResponse () {
      if (this.$refs.responseForm.validate()) {
        this.btnLoading = true

        let load = {
          disputeId: this.disputeId,
          message: this.message,
          complainant: this.complainant._id
        }

        this.$store.dispatch('disputes/ADD_RESPONSE', load)
          .then(res => {
            this.$emit('responseAdded')
            this.socket.emit('dispute-reply', {
              notifType: 'Dispute Response',
              email: this.complainant.email,
              disputeId: this.disputeId
            })
          })
          .catch(error => {
            this.$swal({
              type: 'error',
              title: 'Something went wrong with the server!',
              text: error.message,
              onOpen: () => {
                document.activeElement.blur()
              }
            })
          })
          .finally(() => {
            this.btnLoading = false
          })
      }
    }
  }
}
</script>

<style>

</style>
