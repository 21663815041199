<template>
  <v-container v-if="!notFound">
    <v-row v-if="!isLoading" justify="center">
      <v-col cols="12" lg="7" xl="6">
        <v-card v-if="$store.getters['user/user'].user._id === dispute.complainant._id && !dispute.defendantMessage" :class="$vuetify.breakpoint.mdAndUp ? 'primary white--text mb-2 py-2 px-3' : 'primary white--text mb-2 py-2 px-3 body-2'">
          <span>If the lawyer does not respond within 10 days, we will automatically resolve the dispute in your favor and you will be refunded accordingly. Otherwise, we will evaluate your dispute and give you a decision within 3 working days after the lawyer has responded.</span>
        </v-card>
        <v-card v-if="dispute.defendantMessage && dispute.complainantMessage && !dispute.isResolve" color="primary" :class="$vuetify.breakpoint.mdAndUp ? 'white--text mb-2 py-2 px-3' : 'white--text mb-2 py-2 px-3 body-2'">
          <span>Thank you. We will review the dispute and have this resolved within 3 to 5 working days.</span>
        </v-card>

        <v-card>
          <v-row :class="dispute.awardedTo == 'Complainant' ? 'winner-bg white--text pa-3' : ''" class="pl-5">
            <v-col cols="1" class="pa-0 pt-2">
              <v-avatar :size="$vuetify.breakpoint.mdAndUp ? '60' : '40'" tile>
                <v-img
                  v-if="!isLawyer"
                  :src="dispute.complainant.imageUrl || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'"
                  alt="avatar"
                  style="border-radius: 8%"
                ></v-img>
                <v-img
                  v-if="dispute.board.problem.isAnonymous && isLawyer"
                  src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png"
                  alt="avatar"
                  style="border-radius: 8%"
                ></v-img>
                <v-img
                  v-if="!dispute.board.problem.isAnonymous && isLawyer"
                  :src="dispute.complainant.imageUrl || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'"
                  alt="avatar"
                  style="border-radius: 8%"
                ></v-img>
              </v-avatar>
              
            </v-col>
            <v-col cols="11" :class="$vuetify.breakpoint.mdAndUp ? 'pl-3 pt-2' : 'pt-1 px-5'">
              <span :class="$vuetify.breakpoint.mdAndUp ? 'subheading font-weight-medium' : 'body-2 font-weight-medium'" v-if="!isLawyer">{{ dispute.complainant.fullName }}<span> - Complainant</span></span>
              <span :class="$vuetify.breakpoint.mdAndUp ? 'subheading font-weight-medium' : 'body-2 font-weight-medium'" v-if="!dispute.board.problem.isAnonymous && isLawyer">{{ dispute.complainant.fullName }}<span> - Complainant</span></span>
              <span :class="$vuetify.breakpoint.mdAndUp ? 'subheading font-weight-medium' : 'body-2 font-weight-medium'" v-if="dispute.board.problem.isAnonymous && isLawyer">Anonymous User<span> - Complainant</span></span>
              <p v-for="line in dispute.complainantMessage.split('\n')" :key="line.key" :class="$vuetify.breakpoint.mdAndUp ? '' : 'caption'">{{ line }}</p>
            </v-col>
          </v-row>

          <v-row :class="dispute.awardedTo == 'Defendant' ? 'winner-bg white--text pa-3' : ''" class="pl-5">
            <v-col cols="1" class="pa-0 pt-2">
              <v-avatar :size="$vuetify.breakpoint.mdAndUp ? '60' : '40'" tile>
                <v-img
                  :src="dispute.defendant.imageUrl || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'"
                  alt="avatar"
                  style="border-radius: 8%"
                ></v-img>
              </v-avatar>
            </v-col>
            <v-col cols="11" :class="$vuetify.breakpoint.mdAndUp ? 'pl-3 pt-2' : 'px-5 pt-1'">
              <span :class="$vuetify.breakpoint.mdAndUp ? 'subheading font-weight-medium' : 'body-2 font-weight-medium'">{{ dispute.defendant.fullName }}<span> - Respondent</span></span>
              <div v-if="dispute.defendantMessage">
                <p v-for="line in dispute.defendantMessage.split('\n')" :key="line.key" :class="$vuetify.breakpoint.mdAndUp ? '' : 'caption'">{{ line }}</p>
              </div>
              <div v-else>
                <p class="body-2 grey--text">Lawyer has not yet responded.</p>
              </div>
            </v-col>
          </v-row>
        </v-card>

        <ResponseArea v-if="!responded && dispute.defendant._id === $store.getters['user/user'].user._id" :disputeId="disputeId" @responseAdded="responseAdded" :complainant="dispute.complainant"/>
        <v-sheet v-if="dispute.resolveMessage" class="px-3 pt-3 pb-1 mt-3 dropShadow">
          <span class="body-2 primary--text">Arbitration Team Message</span>
          <div class="px-3">
            <p v-for="line in dispute.resolveMessage.split('\n')" :key="line.index">{{ line }}</p>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
    <div v-else class="pb-5" style="height: 88vh;">
      <v-layout row wrap justify-center fill-height align-center>
        <v-progress-circular
          :size="100"
          :width="5"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-layout>
    </div>
  </v-container>
  <v-container v-else class="text-xs-center">
    <NoData />
  </v-container>
</template>

<script>
import ResponseArea from '../../components/DisputeComponents/AddResponse'
import NoData from '@/views/errors/NoData'

export default {
  components: {
    ResponseArea,
    NoData
  },
  props: [
    'disputeId',
    'problemTitle'
  ],
  data () {
    return {
      responded: false,
      notFound: false
    }
  },
  methods: {
    responseAdded () {
      this.responded = true
    },
    getDispute () {
      this.$store.dispatch('disputes/GET_SELECTED_DISPUTE', this.disputeId)
        .then(res => {
          if (res.defendantMessage) {
            this.responded = true
          } else {
            this.responded = false
          }
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.notFound = true
          } else {
            this.$swal({
              type: 'error',
              title: err.response ? `Error Code: ${err.response.status}` : err.message,
              text: err.response ? err.response.data : '',
              showConfirmButton: false,
              showCloseButton: true
            })
          }
        })
    }
  },
  created () {
    this.getDispute()
  },
  mounted () {
    this.$store.dispatch('disputes/GET_SELECTED_DISPUTE', this.$route.params.disputeId)
  },
  watch: {
    '$route.params.disputeId'(newId, oldId) {
      this.$store.dispatch('disputes/GET_SELECTED_DISPUTE', newId)
    }
  },
  computed: {
    dispute () {
      return this.$store.getters['disputes/selectedDispute']
    },
    isLoading () {
      return this.$store.getters['disputes/isLoading']
    },
    isLawyer () {
      return this.$store.getters['user/user'].user.isLawyer
    }
  }
}
</script>

<style scoped>
  .winner {
    border-style: solid;
    border-color: #1CD1A1;
  }
  .winner-bg {
    background: #1CD1A1;
  }
</style>
